import { ReactNode } from 'react';
import { Tooltip } from 'antd';
import { twJoin, twMerge } from 'tailwind-merge';
import { LoadingOutlined } from '@ant-design/icons';

type Type = 'primary' | 'secondary' | 'black';

interface IButton {
    children?: ReactNode;
    onClick?: (ev) => void;
    htmlType?: 'button' | 'submit' | 'reset';
    form?: string;
    icon?: ReactNode;
    iconPosition?: 'left' | 'right';
    className?: string;
    disabled?: boolean;
    tooltip?: {
        title: string;
        color?: string;
        placement?: 'top' | 'bottom' | 'left' | 'right';
    };
    isLoading?: boolean;
    onBlur?: () => void;
}

interface ButtonBasicProps extends IButton {
    type?: Type;
}

interface ButtonInputProps extends IButton {
    type: 'input';
    label?: string;
}

type ButtonProps = ButtonInputProps | ButtonBasicProps;

const getColorsByType = (type: Type) => {
    switch (type) {
        case 'primary':
            return 'btn-primary';
        case 'secondary':
            return 'btn-secondary';
        case 'black':
            return 'btn-black';
    }
};

const Button = ({
    children,
    htmlType = 'button',
    type = 'primary',
    iconPosition = 'right',
    form,
    onClick,
    icon,
    className,
    disabled,
    tooltip,
    isLoading,
    onBlur,
    ...props
}: ButtonProps) => {
    const buttonElement = (
        <button
            type={htmlType}
            className={twJoin(
                type === 'input' ? '' : 'btn',
                twMerge(
                    'group',
                    type === 'input'
                        ? 'h-10 w-full border border-solid border-pulppo-secondary-gray-disabled px-2 text-pulppo-primary-gray'
                        : getColorsByType(type),
                    icon ? (!children ? 'flex items-center justify-center' : 'flex items-center justify-between') : '',
                    className
                )
            )}
            form={form}
            onClick={onClick}
            disabled={disabled || isLoading}
            onBlur={onBlur}
        >
            {type === 'input' ? (
                <>
                    {iconPosition === 'left' ? icon : null}
                    {children}
                    {iconPosition === 'right' ? icon : null}
                </>
            ) : (
                <>
                    {isLoading ? (
                        <LoadingOutlined />
                    ) : (
                        <>
                            {children}
                            {icon}
                        </>
                    )}
                </>
            )}
        </button>
    );
    if (tooltip) {
        return (
            <Tooltip title={tooltip.title} color={tooltip?.color || '#000000'} placement={tooltip?.placement}>
                <div>{buttonElement}</div>
            </Tooltip>
        );
    }
    if (type === 'input' && (props as ButtonInputProps).label) {
        return (
            <div>
                <p className="mb-1.5 text-sm font-bold">{(props as ButtonInputProps).label}</p>
                {buttonElement}
            </div>
        );
    }
    return buttonElement;
};

export { Button };

export default Button;
