import { useRouter } from 'next/router';
import { useContext } from 'react';
import { publicRoutes, routes } from './routes';
import { UserContext } from '../../contexts/UserContext';
import {
    ApiOutlined,
    CloseOutlined,
    GiftOutlined,
    LogoutOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import { PulppoOptions } from '../Elements/PulppoOptions';
import { UserAvatar } from '../Home/UserAvatar';
import { useTranslate } from '@tolgee/react';
import { HelpIcon } from './Icons';
import { BriefcaseIcon } from '@heroicons/react/outline';

export const LeftSidebarContent = ({ showAll = false, onClose = null, setShowDrawer = (_: boolean) => {} }) => {
    const router = useRouter();
    const { user, logout } = useContext(UserContext);
    const { t } = useTranslate('left_menu');
    const suspended = !!user?.suspensions?.length;
    return (
        <div className="flex h-full flex-col">
            <div className="flex flex-1 flex-col items-start">
                <div className="flex h-[65px] w-full items-center justify-between gap-4 py-2 md:h-[88px]">
                    <div className="flex flex-1 items-center justify-start gap-4 overflow-x-hidden">
                        <div
                            className={`shrink-0 ${showAll ? 'hidden' : 'block'} h-10 w-10 flex-1 p-2 group-hover:hidden 2xl:h-12 2xl:w-12`}
                        >
                            <img
                                src={process.env.NEXT_PUBLIC_LOGO_NEGRO_SOLO || '/iso.svg'}
                                alt="Pulppo logo"
                                className="block h-full w-full object-contain"
                                width={128}
                                height={128}
                            />
                        </div>
                        <div
                            className={`shrink-0 ${showAll ? 'block' : 'hidden'} flex h-12 flex-1 justify-start p-0 group-hover:block md:p-2`}
                        >
                            <img
                                width={128}
                                height={128}
                                className="ml-5 block h-full object-contain md:ml-0 md:w-full"
                                // style={{
                                //     height: '34px',
                                //     width: '137px',
                                //     minWidth: '137px'
                                // }}
                                src={process.env.NEXT_PUBLIC_LOGO_NEGRO || 'https://pulppo.com/images/logo_claro.svg'}
                                alt="Pulppo logo"
                            />
                        </div>
                    </div>
                    <div className="md:hidden">
                        <CloseOutlined
                            width={34}
                            className="mr-3 rounded-sm bg-black p-1 text-white"
                            onClick={() => onClose?.()}
                        />
                    </div>
                </div>

                {routes
                    .filter((route) => !route?.disabled?.(user))
                    .map((route, i) =>
                        route?.title === 'divider' ? (
                            <div key={`divider-${i}`} className="my-1 h-px w-full bg-gray-200" />
                        ) : (
                            <div
                                onClick={() => {
                                    const params = route.query ? route.query?.(user) : '';
                                    router.push(`${route?.route}${params ? `?${params}` : ''}`);
                                    onClose?.();
                                }}
                                key={route?.route}
                                className="relative flex w-full cursor-pointer items-center justify-start overflow-x-hidden"
                                style={{
                                    background: route?.condition?.(router.pathname) ? '#212322' : '',
                                    color: route?.condition?.(router.pathname) ? '#FFF' : ''
                                }}
                            >
                                <div className="flex w-16 items-center justify-center py-2">
                                    {route?.icon(route?.condition?.(router.pathname))}
                                </div>
                                <div
                                    className={`m-0 ${
                                        route?.condition?.(router.pathname)
                                            ? 'font-normal'
                                            : 'font-medium md:font-normal'
                                    } ${showAll ? 'block' : 'hidden'} group-hover:block`}
                                >
                                    {t(route?.title)}
                                </div>
                            </div>
                        )
                    )}
            </div>
            <div className="flex flex-col items-start">
                <div
                    onClick={() => {
                        (window as any)?.Intercom?.('show');
                    }}
                    className="relative flex cursor-pointer items-center justify-start overflow-x-hidden whitespace-nowrap"
                >
                    <div className="flex w-16 items-center justify-center py-2">
                        <HelpIcon />
                    </div>
                    <div className={`m-0 font-medium group-hover:block md:font-normal ${showAll ? 'block' : 'hidden'}`}>
                        {t('help')}
                    </div>
                </div>
                <PulppoOptions
                    title={t('profile_options')}
                    value={{
                        available: user?.available ? 'available' : 'unavailable'
                    }}
                    options={[
                        {
                            label: t('profile'),
                            value: 'profile',
                            disabled: !user?.uid,
                            icon: (
                                <div className="rounded-md bg-gray-100 p-2">
                                    <UserOutlined />
                                </div>
                            ),
                            description: t('profile_description'),
                            onClick: async () => {
                                onClose?.();
                                router.push(`/agent/${user?.uid}`);
                            }
                        },
                        {
                            label: t('company'),
                            value: 'company',
                            disabled: !user?.permissions?.users?.edit,
                            icon: (
                                <div className="rounded-md bg-gray-100 p-2">
                                    <TeamOutlined />
                                </div>
                            ),
                            description: t('company_description'),
                            onClick: async () => {
                                onClose?.();
                                router.push(`/company?company=${user?.company?._id}`);
                            }
                        },
                        process.env.NEXT_PUBLIC_HIDE_LOYALTY !== 'true' && {
                            label: t('benefits'),
                            value: 'benefits',
                            icon: (
                                <div className="rounded-md bg-gray-100 p-2">
                                    <GiftOutlined />
                                </div>
                            ),
                            description: t('benefits_description'),
                            onClick: async () => {
                                onClose?.();
                                router.query.loyalty = 'true';
                                router.push(router);
                            }
                        },
                        process.env.NEXT_PUBLIC_SHOW_JOBS === 'true' &&
                            user?.permissions?.users?.edit && {
                                label: t('jobs'),
                                value: 'jobs',
                                icon: (
                                    <div className="rounded-md bg-gray-100 p-2">
                                        <BriefcaseIcon className="h-5 w-5" />
                                    </div>
                                ),
                                description: t('jobs_description'),
                                onClick: async () => {
                                    onClose?.();
                                    // router.query.loyalty = 'true';
                                    router.push('/jobs');
                                }
                            },
                        {
                            label: t('suggestion'),
                            value: 'suggestion',
                            icon: (
                                <div className="rounded-md bg-gray-100 p-2">
                                    <img className="h-6 w-6" src="/icons/icon-feedback.png" alt="feedback" />
                                </div>
                            ),
                            description: t('suggestion_description'),
                            onClick: async () => {
                                onClose?.();
                                const popupOrRedirect = (url) => {
                                    const tryPopup = (url) => {
                                        try {
                                            const newWindow = window.open(url, '_blank');
                                            if (
                                                !newWindow ||
                                                newWindow.closed ||
                                                typeof newWindow.closed == 'undefined'
                                            ) {
                                                return null;
                                            }

                                            (newWindow as Window).window.focus();
                                            newWindow.addEventListener('load', function () {});
                                            return newWindow;
                                        } catch (e) {
                                            return null;
                                        }
                                    };
                                    if (!tryPopup(url)) {
                                        window.location.href = url;
                                    }
                                };
                                popupOrRedirect('https://pulppo.canny.io/nuevas-funcionalidades');
                            }
                        },
                        process.env.NEXT_PUBLIC_COMPLEMENTARY_SERVICES === 'true' && {
                            label: t('providers'),
                            value: 'providers',
                            isNew: true,
                            icon: (
                                <div className="rounded-md bg-gray-100 p-2">
                                    <ApiOutlined />
                                </div>
                            ),
                            description: t('providers_description'),
                            onClick: async () => {
                                onClose?.();
                                router.push('/requests');
                            }
                        },
                        {
                            label: t('logout'),
                            value: 'close-session',
                            icon: (
                                <div className="rounded-md bg-gray-100 p-2">
                                    <LogoutOutlined />
                                </div>
                            ),
                            description: t('logout_description'),
                            onClick: async () => {
                                onClose?.();
                                await logout();
                                setShowDrawer(false);
                            }
                        }
                    ].filter(Boolean)}
                >
                    <div className="relative flex w-full cursor-pointer items-center justify-start gap-1 overflow-x-hidden whitespace-nowrap md:mb-0">
                        <div className="flex w-16 items-center justify-center py-2">
                            <UserAvatar className="h-10 w-10 lg:h-12 lg:w-12" agent={user} />
                        </div>

                        <div className={`m-0 group-hover:block ${showAll ? 'block' : 'hidden'}`}>
                            <div className="font-medium md:font-normal">
                                {user?.firstName} {user?.lastName}
                            </div>
                            <div className="flex items-center gap-2">
                                <div
                                    className="h-3 w-3 rounded-full"
                                    style={{
                                        background: user?.available ? '#47CF4C' : '#CF474C'
                                    }}
                                />
                                {suspended
                                    ? t('Pausa de consultas')
                                    : user?.available
                                      ? t('available')
                                      : t('unavailable')}
                            </div>
                        </div>
                    </div>
                </PulppoOptions>
            </div>
        </div>
    );
};

const LeftSidebar = () => {
    const router = useRouter();

    return (
        <div
            style={{ zIndex: 999 }}
            className={`group absolute h-full w-16 flex-col justify-between bg-white shadow-md transition-all hover:w-72 ${
                publicRoutes.includes(router.pathname) ? 'hidden' : 'hidden md:flex'
            }`}
        >
            <LeftSidebarContent />
        </div>
    );
};

export default LeftSidebar;
